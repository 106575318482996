import React from 'react';
import {Society, SocietyAllFormData, SocietyForm} from "../../../../../corelogic/models/society";
import {Autocomplete, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Tooltip} from "@mui/material";
import {InfoOutlined, LocationOnOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../i18n/messages";
import {DividingHR} from "../../Utils/DividingHR";
import {Controller, useForm} from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import BadgeIcon from '@mui/icons-material/Badge';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

interface SocietyFormInterface {
    dataFormFields?: SocietyAllFormData
    societySelected?: Society
    onUpdate?: (societyForm: SocietyForm) => void
    onReset?: () => void
}

const createEmailArraySchema = (fieldName: string) => {
    return yup.array()
        .test(`email-length-${fieldName}`, 'Total email length exceeds 255 characters', function (value) {
            const {path} = this
            return String(value).length <= 255 || this.createError({
                path,
                message: 'Vous ne pouvez pas rentrer plus de 255 caractères'
            })
        })
        .transform(function (value, originalValue) {
            if (this.isType(value) && value !== null) {
                return value
            }
            return originalValue ? originalValue.split(/[\s;]+/) : [];
        })
        .test(`valid-${fieldName}`, 'One or more emails are invalid', function (value) {
            const {path} = this
            return value?.every((email) => yup.string().email().isValidSync(email)) || this.createError({
                path,
                message: 'Un ou plusieurs emails sont invalides et veuillez utiliser le délimitateur ";"'
            })
        })
}

const schemaValidationGeneralInformation = yup.object({
    orderSubmissionEmails: createEmailArraySchema('orderSubmissionEmails'),
    mail: yup.string().email("L'email n'est pas valide"),
    website: yup.string().trim().test(
        'valid-url',
        "Le lien n'est pas valide",
        function (value) {
            if (!value || value.trim() === '') {
                return true
            }
            return /^(https?:\/\/)?(?:www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(value)
        }
    ),
    techInterlocutor: yup.object().shape({
        mail: yup.string().email("L'email n'est pas valide")
    }),
    adminInterlocutor: yup.object().shape({
        mail: yup.string().email("L'email n'est pas valide")
    }),
    comInterlocutor: yup.object().shape({
        mail: yup.string().email("L'email n'est pas valide")
    })
})

const SocietyFormComponent = (props: SocietyFormInterface) => {
    const {dataFormFields, onUpdate, societySelected, onReset} = props
    const intl = useIntl()
    const {control, handleSubmit, formState: {errors}, getValues} = useForm<SocietyForm>(
        {
            resolver: yupResolver(schemaValidationGeneralInformation),
            defaultValues: {
                label: societySelected?.label,
                siret: societySelected?.siret,
                fax: societySelected?.fax,
                website: societySelected?.website,
                mail: societySelected?.mail,
                phone: societySelected?.phone,
                businessName: societySelected?.businessName,
                city: societySelected?.city,
                address1: societySelected?.address1,
                address2: societySelected?.address2,
                address3: societySelected?.address3,
                zipCode1: societySelected?.zipCode1,
                zipCode2: societySelected?.zipCode2,
                stockCapital: societySelected?.stockCapital,
                eanCode: societySelected?.eanCode,
                apeNaf: societySelected?.apeNaf,
                intraVat: societySelected?.intraVat,
                rcs: societySelected?.rcs,
                techInterlocutor: societySelected?.techInterlocutor,
                comInterlocutor: societySelected?.comInterlocutor,
                adminInterlocutor: societySelected?.adminInterlocutor,
                countryId: societySelected?.country?.id,
                languageId: societySelected?.language?.id,
                currencyId: societySelected?.currency?.id,
                freeManagementOrderLine: societySelected?.freeManagementOrderLine,
                sendEmailAtOrderSubmission: societySelected?.sendEmailAtOrderSubmission,
                blockAllEmailShipmentsAtOrderSubmission: societySelected?.blockAllEmailShipmentsAtOrderSubmission,
                orderSubmissionEmails: societySelected?.orderSubmissionEmails,
                authorizationToChangeDiscounts: societySelected?.authorizationToChangeDiscounts,
                authorizationToChangeTheGrossPrice: societySelected?.authorizationToChangeTheGrossPrice,
                sendingMailAtUserCreation: societySelected?.sendingMailAtUserCreation,
                authorizationToChangeOrderLineItemLabel: societySelected?.authorizationToChangeOrderLineItemLabel,
                replaceOrderLineItemLabelFromOrderScheduler: societySelected?.replaceOrderLineItemLabelFromOrderScheduler,
                defaultOrderUnitPackage: societySelected?.defaultOrderUnitPackage,
                shippingVatId: societySelected?.shippingVat?.id
            }
        }
    )
    const validateForm = (data: SocietyForm) => {
        if (data.orderSubmissionEmails) {
            const stringBeforeUpdate = data.orderSubmissionEmails.toString()
            const stringUpdateFormat = stringBeforeUpdate.replaceAll(",", ";")

            if (onUpdate) {
                if (stringUpdateFormat.at(-1) === ";") {
                    onUpdate({...data, orderSubmissionEmails: stringUpdateFormat.slice(0, -1)})
                } else {
                    onUpdate({...data, orderSubmissionEmails: stringUpdateFormat})
                }
            }
        } else if (data.website) {
            const stringUpdated = data.website.trim()
            if (onUpdate) {
                onUpdate({...data, website: stringUpdated})
            }
        }
    }

    const handleReset = () => {
        if (onReset) {
            onReset()
        }
    }

    return (
        <form onSubmit={handleSubmit(validateForm)} onReset={handleReset}>
            <Grid container spacing={1}>
                <Grid item sm={11}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container>
                            <Paper sx={{width: "100%"}}>
                                <Grid container item alignItems="center" p={1}>
                                    <InfoOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageGeneralInformationTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container spacing={1}>
                                    <Grid item sm={12} md={6}>
                                        <Grid container direction="column" p={2} spacing={1}>
                                            <Grid item>
                                                <Controller
                                                    name="label"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            autoFocus
                                                            fullWidth
                                                            required
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societyLabel"))}
                                                            type="text"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="languageId"
                                                    control={control}
                                                    render={({field: {onChange}}) =>
                                                        <Autocomplete
                                                            options={dataFormFields?.languages ? dataFormFields?.languages : []}
                                                            onChange={(event, param) => {
                                                                onChange(param?.id)
                                                            }}
                                                            value={dataFormFields?.languages.find(l => l.id === getValues("languageId"))}
                                                            getOptionLabel={(option => option.label || "")}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            renderInput={(params) => <TextField {...params}
                                                                                                label={intl.formatMessage(getMessageDescriptor("societyLanguage"))}
                                                                                                variant="standard"
                                                            />}
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="currencyId"
                                                    control={control}
                                                    render={({field: {onChange}}) =>
                                                        <Autocomplete
                                                            options={dataFormFields?.currencies ? dataFormFields?.currencies : []}
                                                            onChange={(event, param) => {
                                                                onChange(param?.id)
                                                            }}
                                                            value={dataFormFields?.currencies.find(l => l.id === getValues("currencyId"))}
                                                            getOptionLabel={(option => option.label || "")}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            renderInput={(params) => <TextField {...params}
                                                                                                label={intl.formatMessage(getMessageDescriptor("societyCurrency"))}
                                                                                                variant="standard"
                                                            />}
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="siret"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societySiret"))}
                                                            type="text"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="intraVat"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societyIntraCommunityVAT"))}
                                                            type="text"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="apeNaf"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societyApeNaf"))}
                                                            type="text"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="eanCode"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societyEan"))}
                                                            type="text"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="rcs"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societyRcs"))}
                                                            type="text"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="stockCapital"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societySocialCapital"))}
                                                            type="number"
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    name="shippingVatId"
                                                    control={control}
                                                    render={({field: {onChange}}) =>
                                                        <Autocomplete
                                                            options={dataFormFields?.vatTypes ? dataFormFields?.vatTypes : []}
                                                            onChange={(event, param) => {
                                                                onChange(param?.id)
                                                            }}
                                                            value={dataFormFields?.vatTypes.find(l => l.id === getValues("shippingVatId"))}
                                                            getOptionLabel={(option => option.label || "")}
                                                            isOptionEqualToValue={(option, value) => option === value}
                                                            renderInput={(params) => <TextField {...params}
                                                                                                label={intl.formatMessage(getMessageDescriptor("societyShippingVat"))}
                                                                                                variant="standard"
                                                            />}
                                                        />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <Grid container direction="column" p={2} spacing={1}>
                                            <Grid item>
                                                <Controller
                                                    name="orderSubmissionEmails"
                                                    control={control}
                                                    render={({field: {...field}}) =>
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            label={intl.formatMessage(getMessageDescriptor("societyMailsForOrderSubmission"))}
                                                            type="text"
                                                            error={!!errors.orderSubmissionEmails}
                                                            helperText={errors.orderSubmissionEmails?.message}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailIcon/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    label={intl.formatMessage(getMessageDescriptor("userPageFormFieldSendEmailAtOrderSubmission"))}
                                                    control={
                                                        <Controller
                                                            name="sendEmailAtOrderSubmission"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    label={intl.formatMessage(getMessageDescriptor("societyFormFieldBlockAllEmailShipmentsAtOrderSubmission"))}
                                                    control={
                                                        <Controller
                                                            name="blockAllEmailShipmentsAtOrderSubmission"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    label={intl.formatMessage(getMessageDescriptor("societyFormFieldFreeManagementOrderLine"))}
                                                    control={
                                                        <Controller
                                                            name="freeManagementOrderLine"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    label={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeDiscounts"))}
                                                    control={
                                                        <Controller
                                                            name="authorizationToChangeDiscounts"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    label={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                    control={
                                                        <Controller
                                                            name="authorizationToChangeTheGrossPrice"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    // label={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                    label={"Envoi mail création utilisateur"}
                                                    control={
                                                        <Controller
                                                            name="sendingMailAtUserCreation"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    // label={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                    label={"Autorisation de modifier un label de ligne de commande"}
                                                    control={
                                                        <Controller
                                                            name="authorizationToChangeOrderLineItemLabel"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    // label={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                    label={"Remplacer le label article de la ligne de commande par celui du cadencier"}
                                                    control={
                                                        <Controller
                                                            name="replaceOrderLineItemLabelFromOrderScheduler"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    // label={intl.formatMessage(getMessageDescriptor("societyFormFieldAuthorizationToChangeTheGrossPrice"))}
                                                    label={"Colis proposé par défaut en saisie de commande"}
                                                    control={
                                                        <Controller
                                                            name="defaultOrderUnitPackage"
                                                            control={control}
                                                            render={({field: props}) => (
                                                                <Checkbox
                                                                    {...props}
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item container spacing={1}>
                            <Grid item lg={6} md={12}>
                                <Paper sx={{width: "100%"}}>
                                    <Grid container item alignItems="center" p={1}>
                                        <LocationOnOutlined
                                            style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                        <Typography variant="h6" component="h2" fontWeight="bolder">
                                            <FormattedMessage id={messages.customerPageDeliveryAddressTitle.id}/>
                                        </Typography>
                                    </Grid>
                                    <DividingHR style={{marginTop: 0}}/>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="businessName"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("societyFormFieldBusinessName"))}
                                                        type="text"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="address1"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerAddressAddress1"))}
                                                        type="text"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="address2"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerAddressAddress2"))}
                                                        type="text"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="address3"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerAddressAddress3"))}
                                                        type="text"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="zipCode1"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerAddressZipCode1"))}
                                                        type="text"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="zipCode2"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerAddressZipCode2"))}
                                                        type="text"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="city"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerAddressCity"))}
                                                        type="text"
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="countryId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Autocomplete
                                                        options={dataFormFields?.countries ? dataFormFields?.countries : []}
                                                        onChange={(event, param) => {
                                                            onChange(param?.id)
                                                        }}
                                                        value={dataFormFields?.countries.find(l => l.id === getValues("countryId"))}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage(getMessageDescriptor("customerAddressCountry"))}
                                                                                            variant="standard"
                                                        />}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item lg={6} md={12}>
                                <Paper sx={{width: "100%"}}>
                                    <Grid container item alignItems="center" p={1}>
                                        <LocationOnOutlined
                                            style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                        <Typography variant="h6" component="h2" fontWeight="bolder">
                                            <FormattedMessage id={messages.customerPageContactInformationTitle.id}/>
                                        </Typography>
                                    </Grid>
                                    <DividingHR style={{marginTop: 0}}/>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhoneIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="fax"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldFax"))}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FaxIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="mail"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                        type="text"
                                                        error={!!errors.mail}
                                                        helperText={errors.mail?.message}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="website"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldWebsite"))}
                                                        type="text"
                                                        error={!!errors.website}
                                                        helperText={errors.website?.message}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LanguageIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item lg={4} md={12}>
                                <Paper sx={{width: "100%"}}>
                                    <Grid container item alignItems="center" p={1}>
                                        <ContactPhoneOutlinedIcon
                                            style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                        <Typography variant="h6" component="h2" fontWeight="bolder">
                                            Interlocuteur commercial
                                        </Typography>
                                    </Grid>
                                    <DividingHR style={{marginTop: 0}}/>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="comInterlocutor.name"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("societyFormFieldName"))}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <BadgeIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="comInterlocutor.phone"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhoneIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="comInterlocutor.mail"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                        type="text"
                                                        error={!!errors.comInterlocutor?.mail}
                                                        helperText={errors.comInterlocutor?.mail?.message}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item lg={4} md={12}>
                                <Paper sx={{width: "100%"}}>
                                    <Grid container item alignItems="center" p={1}>
                                        <ContactPhoneOutlinedIcon
                                            style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                        <Typography variant="h6" component="h2" fontWeight="bolder">
                                            Interlocuteur technique
                                        </Typography>
                                    </Grid>
                                    <DividingHR style={{marginTop: 0}}/>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="techInterlocutor.name"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("societyFormFieldName"))}
                                                        type="text"
                                                        error={!!errors.techInterlocutor?.name}
                                                        helperText={errors.techInterlocutor?.name?.message}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <BadgeIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="techInterlocutor.phone"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhoneIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="techInterlocutor.mail"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                        type="text"
                                                        error={!!errors.techInterlocutor?.mail}
                                                        helperText={errors.techInterlocutor?.mail?.message}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item lg={4} md={12}>
                                <Paper sx={{width: "100%"}}>
                                    <Grid container item alignItems="center" p={1}>
                                        <ContactPhoneOutlinedIcon
                                            style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                        <Typography variant="h6" component="h2" fontWeight="bolder">
                                            Interlocuteur administratif
                                        </Typography>
                                    </Grid>
                                    <DividingHR style={{marginTop: 0}}/>
                                    <Grid container direction="column" p={2} spacing={1}>
                                        <Grid item>
                                            <Controller
                                                name="adminInterlocutor.name"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("societyFormFieldName"))}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <BadgeIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="adminInterlocutor.phone"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhoneIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name="adminInterlocutor.mail"
                                                control={control}
                                                render={({field: {...field}}) =>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="standard"
                                                        label={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                        type="text"
                                                        error={!!errors.adminInterlocutor?.mail}
                                                        helperText={errors.adminInterlocutor?.mail?.message}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                    <Paper sx={{
                        position: "sticky",
                        top: "145px",
                        zIndex: 1000
                    }}>
                        <Grid container direction="column">
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("genericValidate"))}>
                                <Button type="submit"><CheckCircleIcon/></Button>
                            </Tooltip>
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("genericCancel"))}>
                                <Button type="reset"><CancelIcon
                                    sx={{fill: "rgba(255, 82, 82, 1)"}}/></Button>
                            </Tooltip>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </form>
    );
};

export default SocietyFormComponent;